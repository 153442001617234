html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
html {
    height: 100%;
}
em {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

:focus {
	outline: 0;
}
body {
	line-height: 1;
	color: black;
	background: white;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	position: relative;
	overflow: auto;
    height: 100%;
}
ol, ul {
	list-style: none;
}
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}
sub {
	vertical-align: sub;
	font-size: 0.9em;
}

sup {
	vertical-align: super;
	font-size: 0.9em;
}