.column-splitter,
.row-splitter {
  width: 100%;
}

.component {
  box-sizing: border-box;
  margin-bottom: 0.875rem;
}

section.component {
  margin-bottom: 0;
}

/* Bootstrap 4 fixes */
.row.column-splitter > div > div.row {
  margin-left: 0;
  margin-right: 0;
}

.tabs .tabs-heading > li .scEnabledChrome > .row,
.tabs .tabs-container > .tab > .scEnabledChrome > .row {
  margin-left: 0;
  margin-right: 0;
}

.accordion .scEnabledChrome > .toggle-header > .label > .row,
.accordion .scEnabledChrome > .toggle-content > .row {
  margin-left: 0;
  margin-right: 0;
}

.carousel .scEnabledChrome > .row {
  margin-left: 0;
  margin-right: 0;
}

.snippet .scEnabledChrome > .row {
  margin-left: 0;
  margin-right: 0;
}

.container > .component-content > .row {
  margin-left: 0;
  margin-right: 0;
}

/* Bootstrap 4 fixes end */

/* background image stretch mode */
.component.sc-background-image-stretch {
  background-size: 100% 100%;
}

.component.sc-background-image-stretch-vertically {
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.component.sc-background-image-stretch-horizontally {
  background-repeat: repeat-y;
  background-size: 100% auto;
}

.component.sc-background-image-tile {
  background-repeat: repeat;
}

.component.sc-background-image-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.component.sc-background-image-fixed {
  background-attachment: fixed;
}

/* End of background image stretch mode */
